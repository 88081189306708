import {
    REQUEST_ALL,
    REQUEST_ALL_SUCCESSFUL,
    REQUEST_ALL_FAILED,
    REQUEST_EXECUTIVE_ALL,
    REQUEST_EXECUTIVE_ALL_SUCCESSFUL,
    REQUEST_EXECUTIVE_ALL_FAILED,
    REQUESTED_DATA,
    REQUESTED_DATA_SUCCESSFUL,
    REQUESTED_DATA_FAILED,
    REQUESTED_STATUS,
    REQUESTED_STATUS_SUCCESSFUL,
    REQUESTED_STATUS_FAILED

} from './actionTypes';

export const requestAll = (history) => {

    return {
        type: REQUEST_ALL,
        payload: { history }
    }
}

export const requestAllSuccessful = (request, message) => {
    return {
        type: REQUEST_ALL_SUCCESSFUL,
        payload: request,
        message: message
    }
}

export const requestAllError = (error) => {
    return {
        type: REQUEST_ALL_FAILED,
        error
    }
}

export const requestExecutiveAll = (executive_id, history) => {

    return {
        type: REQUEST_EXECUTIVE_ALL,
        payload: { executive_id, history }
    }
}

export const requestExecutiveSuccessful = (request, message) => {
    return {
        type: REQUEST_EXECUTIVE_ALL_SUCCESSFUL,
        payload: request,
        message: message
    }
}

export const requestExecutiveError = (error) => {
    return {
        type: REQUEST_EXECUTIVE_ALL_FAILED,
        error
    }
}

export const requestedData = (id, history) => {

    return {
        type: REQUESTED_DATA,
        payload: { id, history }
    }
}

export const requestedDataSuccessful = (request, message) => {
    return {
        type: REQUESTED_DATA_SUCCESSFUL,
        payload: request,
        message: message
    }
}

export const requestedDataError = (error) => {
    return {
        type: REQUESTED_DATA_FAILED,
        error
    }
}

//UPDATE REQUEST STATUS


export const requestStatus = (request, history) => {

    return {
        type: REQUESTED_STATUS,
        payload: { request, history }
    }
}

export const requestStatusSuccessful = (request, message) => {
    return {
        type: REQUESTED_STATUS_SUCCESSFUL,
        payload: request,
        message: message
    }
}

export const requestStatusError = (error) => {
    return {
        type: REQUESTED_STATUS_FAILED,
        error
    }
}
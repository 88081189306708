import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import axios from "axios"
import request from "request"
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { API_URL } from "../.././apiUrl"


//Account Redux states
import { REQUEST_ALL, REQUEST_EXECUTIVE_ALL, REQUESTED_DATA, REQUESTED_STATUS } from './actionTypes';
import {
    requestAllSuccessful,
    requestAllError,
    requestExecutiveSuccessful,
    requestExecutiveError,
    requestedDataSuccessful,
    requestedDataError,
    requestStatusSuccessful,
    requestStatusError
} from './actions';

export function* watchAllRequest() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(REQUEST_ALL, allRequestDetails);
}

function* allRequestDetails({ history }) {

    const response = yield fetch(`${API_URL}/admin-request/all`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        /* body: JSON.stringify({
          name: 'apptest3',
          email: 'apptest3@test.be',
          password: '123456789'
        }), */
    })
    // Again yield will wait for Promise to resolve
    console.log("response", response)
    const allRequest = yield response.json()
    console.log(allRequest)
    if (response.status === 200) {
        yield put(requestAllSuccessful(allRequest.data))
    }
    else if (response.status === 206) {
        yield put(requestAllError(allRequest.msg))

    }
}

export function* watchAllExecutiveRequest() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(REQUEST_EXECUTIVE_ALL, allExecutiveRequestDetails);
}

function* allExecutiveRequestDetails({ payload }) {

    const response = yield fetch(`${API_URL}/admin-request/executive_all/${payload.executive_id}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        /* body: JSON.stringify({
          name: 'apptest3',
          email: 'apptest3@test.be',
          password: '123456789'
        }), */
    })
    // Again yield will wait for Promise to resolve
    console.log("response", response)
    const allExecRequest = yield response.json()
    console.log(allExecRequest)
    if (response.status === 200) {
        yield put(requestExecutiveSuccessful(allExecRequest.data))
    }
    else if (response.status === 206) {
        yield put(requestExecutiveError(allExecRequest.msg))

    }
}

export function* watchgetRequestData() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(REQUESTED_DATA, getRequestDataDetails);
}

function* getRequestDataDetails({ payload }) {

    const response = yield fetch(`${API_URL}/admin-request/requestDetail/${payload.id}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        /* body: JSON.stringify({
          name: 'apptest3',
          email: 'apptest3@test.be',
          password: '123456789'
        }), */
    })
    // Again yield will wait for Promise to resolve
    console.log("response", response)
    const requestData = yield response.json()
    console.log(requestData)
    if (response.status === 200) {
        yield put(requestedDataSuccessful(requestData.data))
    }
    else if (response.status === 206) {
        yield put(requestedDataError(requestData.msg))

    }
}


//update request status

const requestStatusUpdateSync = async (request_id, status, remark, history, user_id) => {

    var options = {
        'method': 'POST',
        'url': `${API_URL}/admin-request/update`,
        'headers': {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "request_id": request_id, "status": status, "remark": remark , user_id})

    };
    await request(options, function (error, request) {
        if (error) {

            toastr.warning(error, 'Request Error')
            console.log("error in login", error)
        } else {

            console.log("parsed json request", request)
            var parsedJSON = JSON.parse(request.body)
            if (request.statusCode === 200) {
                put(requestStatusSuccessful(parsedJSON.data));

                toastr.success(parsedJSON.msg, 'Request Status Updated')
                history.push("/request")

            } else if (request.statusCode === 206) {
                toastr.warning(parsedJSON.msg, 'Error in updating request status')

            }
        }
    });



}


function* requestStatusUpdate({ payload }) {
    const { request_id, status, remark, user_id } = payload.request
    const { history } = payload
    try {
        const response = yield call(requestStatusUpdateSync, request_id, status, remark, history, user_id);

    } catch (error) {
        yield put(requestStatusError(error));
    }
}

export function* watchRequestStatus() {
    yield takeEvery(REQUESTED_STATUS, requestStatusUpdate)
}






function* requestSaga() {
    yield all([
        fork(watchAllRequest),
        fork(watchAllExecutiveRequest),
        fork(watchgetRequestData),
        fork(watchRequestStatus)

    ]);
}

export default requestSaga;
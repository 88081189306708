import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { userRegister } from "../../store/auth/register/actions";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import images
import logodark from "../../assets/images/logo-dark.png";

const optionGroup = [
  {
    label: "Picnic",
    options: [
      { label: "Mustard", value: "Mustard" },
      { label: "Ketchup", value: "Ketchup" },
      { label: "Relish", value: "Relish" },
    ],
  },
  {
    label: "Camping",
    options: [
      { label: "Tent", value: "Tent" },
      { label: "Flashlight", value: "Flashlight" },
      { label: "Toilet Paper", value: "Toilet Paper" },
    ],
  },
];

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: "1",
      name: "",
      mobile: "",
      email: "",
      password: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event, values) {
    this.props.userRegister(values);
    /* console.log(values, "values") */
  }

  handleSelectGroup = (selectedGroup) => {
    this.setState({ selectedGroup });
  };

  render() {
    const { selectedGroup } = this.state;
    return (
      <React.Fragment>
        {/*  <div className="home-btn d-none d-sm-block">
                    <Link to="/
                    "><i className="mdi mdi-home-variant h2 text-white"></i></Link>
                </div> */}

        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col lg={6} className="mx-auto">
                <div className="p-4 d-flex align-items-center">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          {
                            <div className="text-center">
                              <div>
                                <Link to="#" className="logo">
                                  <img
                                    src="https://res.cloudinary.com/dx6zgsncl/image/upload/v1632773361/Lucky/Full_logo_o4hybm.png"
                                    height="40"
                                    alt="logo"
                                  />
                                </Link>
                              </div>

                              <h4 className="font-size-18 mt-4">
                                Register An Account
                              </h4>
                              {/* <p className="text-muted">Get your free Nazox account now.</p> */}
                            </div>
                          }
                          {this.props.user && (
                            <Alert color="success">
                              Registration Done Successfully.
                            </Alert>
                          )}

                          {this.props.registrationError && (
                            <Alert color="danger">
                              {this.props.registrationError}
                            </Alert>
                          )}

                          <div className="p-2 mt-5">
                            <AvForm
                              onValidSubmit={this.handleSubmit}
                              className="form-horizontal"
                            >
                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="username">Username</Label>
                                <AvField
                                  name="name"
                                  value={this.state.name}
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  placeholder="Enter username"
                                />
                              </FormGroup>
                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="username">Mobile</Label>
                                <AvField
                                  name="mobile"
                                  value={this.state.mobile}
                                  type="number"
                                  className="form-control"
                                  id="mobile"
                                  placeholder="Enter Mobile Number"
                                />
                              </FormGroup>

                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-mail-line auti-custom-input-icon"></i>
                                <Label htmlFor="useremail">Email</Label>
                                <AvField
                                  name="email"
                                  value={this.state.email}
                                  validate={{ email: true, required: true }}
                                  type="email"
                                  className="form-control"
                                  id="useremail"
                                  placeholder="Enter email"
                                />
                              </FormGroup>

                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="userpassword">Password</Label>
                                <AvField
                                  name="password"
                                  value={this.state.password}
                                  type="password"
                                  className="form-control"
                                  id="userpassword"
                                  placeholder="Enter password"
                                />
                              </FormGroup>
                              {/* <FormGroup className="auth-form-group-custom mb-4"> */}
                              {/* <i className="ri-lock-2-line auti-custom-input-icon"></i> */}
                              <AvField
                                type="hidden"
                                name="roles"
                                className="form-control"
                                value="1"
                              >
                                {/* <option value={1}>Admin</option> */}
                                {/* <option value={2}>Manager</option> */}
                                {/* <option value={3}>Executive</option> */}
                              </AvField>
                              {/* </FormGroup> */}

                              <div className="text-center">
                                <Button
                                  color="primary"
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  {this.props.loading
                                    ? "Loading ..."
                                    : "Register"}
                                </Button>
                              </div>

                              {/*  <div className="mt-4 text-center">
                                                        <p className="mb-0">By registering you agree to the Arkpass <Link to="#" className="text-primary">Terms of Use</Link></p>
                                                    </div> */}
                            </AvForm>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              {/*  <Col lg={8}>
                                <div className="authentication-bg">
                                    <div className="bg-overlay"></div>
                                </div>
                            </Col> */}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { registrationError, loading } = state.UserRequest;

  return { registrationError, loading };
};

export default connect(mapStatetoProps, { userRegister })(Register);

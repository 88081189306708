/* Request Table */

export const REQUEST_ALL = 'REQUEST_ALL';
export const REQUEST_ALL_SUCCESSFUL = 'REQUEST_ALL_SUCCESSFUL';
export const REQUEST_ALL_FAILED = 'REQUEST_ALL_FAILED'

export const REQUEST_EXECUTIVE_ALL = 'REQUEST_EXECUTIVE_ALL';
export const REQUEST_EXECUTIVE_ALL_SUCCESSFUL = 'REQUEST_EXECUTIVE_ALL_SUCCESSFUL';
export const REQUEST_EXECUTIVE_ALL_FAILED = 'REQUEST_EXECUTIVE_ALL_FAILED'

export const REQUESTED_DATA = 'REQUESTED_DATA';
export const REQUESTED_DATA_SUCCESSFUL = 'REQUESTED_DATA_SUCCESSFUL';
export const REQUESTED_DATA_FAILED = 'REQUESTED_DATA_FAILED'


export const REQUESTED_STATUS = 'REQUESTED_STATUS';
export const REQUESTED_STATUS_SUCCESSFUL = 'REQUESTED_STATUS_SUCCESSFUL';
export const REQUESTED_STATUS_FAILED = 'REQUESTED_STATUS_FAILED'
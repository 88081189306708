import React, { Component } from "react";
import {
  Col,
  Container,
  Row,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Card,
  CardBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";

import { API_URL } from "../../apiUrl";

const expandRow = {
  renderer: (row) => (
    <>
      Action :
      <Link to="#" className="mr-3 text-primary">
        <i className="mdi mdi-eye font-size-18 ml-2"></i>
      </Link>
      {/*  <Link to="#" className="text-danger" ><i className="mdi mdi-trash-can font-size-18"></i></Link> */}
    </>
  ),
  showExpandColumn: true,
  expandByColumnOnly: true,
};

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      clients: [],
      breadcrumbItems: [
        { title: "TravelQuail", link: "/" },
        { title: "Clients", link: "/client list" },
      ],
    };
  }

  handleClientsData = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch("http://localhost:3306/admin/user-list", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          clients: result.data,
        });
      })
      .catch((error) => console.log("error", error));
  };

  componentDidMount = () => {
    this.handleClientsData();
  };

  render() {
    const data = {
      columns: [
        {
          dataField: "id",
          text: "No.",
        },
        {
          dataField: "name",
          text: "Name",
        },
        {
          dataField: "mobile",
          text: "Mobile",
        },
        {
          dataField: "email",
          text: "Email",
        },
      ],
      rows:
        this.state.clients &&
        this.state.clients.reverse().map((req, index) => ({
          id: index + 1,
          name: req.name,
          mobile: req.mobile,
          email: req.email,
        })),
    };

    const options = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList: [
        {
          text: "5th",
          value: 10,
        },
        {
          text: "10th",
          value: 10,
        },
        {
          text: "All",
          value: data.rows.length,
        },
      ],
    };

    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Client"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xl={12}>
                {console.log("cleinst", this.state.clients)}

                <BootstrapTable
                  keyField="id"
                  data={data.rows}
                  columns={data.columns}
                  expandRow={expandRow}
                  pagination={paginationFactory(options)}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Clients;

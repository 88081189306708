import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import LatestTransactions from "./LatestTransactions";
import MiniWidgets from "./MiniWidgets";

class StarterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "TravelQuail", link: "#" },
        { title: "Dashboard", link: "#" },
      ],
      reports: [
        {
          icon: "ri-stack-line",
          title: "Total Request",
          value: "1452",
          rate: "2.4%",
          desc: "From previous period",
        },
        {
          icon: "ri-checkbox-line",
          title: "Total Request Handled",
          value: "38452",
          rate: "2.4%",
          desc: "From previous period",
        },
        {
          icon: "mdi mdi-delete-outline",
          title: "Total Request Rejected",
          value: "15",
          rate: "2.4%",
          desc: "From previous period",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Dashboard"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xl={12}>
                <Row>
                  <MiniWidgets reports={this.state.reports} />
                </Row>

                <Row>
                  <LatestTransactions />
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default StarterPage;

import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import request from "request"
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

// Login Redux States
import { CHECK_LOGIN, LOGOUT_USER } from './actionTypes';
import { apiError, loginUserSuccessful, logoutUserSuccess } from './actions';
import {API_URL} from "../../../apiUrl";
import {setCurrentUser} from "../../../helpers/Utils"

// AUTH related methods
import { postLogin } from '../../../helpers/fackBackend_Helper';
import { getFirebaseBackend } from '../../../helpers/firebase_helper';

//Initilize firebase
const fireBaseBackend = getFirebaseBackend();



const postLoginSync = async (email, password, history) => {

    var options = {
        'method': 'POST',
        'url': `${API_URL}/admin/login`,
        'headers': {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "email": email, "password": password })

    };
    await request(options, function (error, profile) {
        if (error) {
            
            toastr.warning(error, 'Account Error')
            console.log("error in login", error)
        } else {

            console.log("parsed json", profile)
            var parsedJSON = JSON.parse(profile.body)
            if (profile.statusCode === 200) {

                setCurrentUser(parsedJSON.data);
                put(loginUserSuccessful(parsedJSON.data));
                history.push('/dashboard');
                toastr.success(parsedJSON.msg, 'Account Created')

            } else if (profile.statusCode === 206) {
                toastr.warning(parsedJSON.msg, 'Error in creating account')

            }
        }
    });



}

//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload }) {
    const { email, password } = payload.user
    const { history } = payload
    try {
        /*  if(process.env.REACT_APP_DEFAULTAUTH === "firebase") {
             const response = yield call(fireBaseBackend.loginUser, user.username, user.password);
             yield put(loginUserSuccessful(response));
         }
         else { */


        const response = yield call(postLoginSync, email, password, history);
        /* console.log("respone login data", response)
        localStorage.setItem("authUser", JSON.stringify(response));
        yield put(loginUserSuccessful(response)); */
        /*   } */

    } catch (error) {
        yield put(apiError(error));
    }
}

function* logoutUser({ payload: { history } }) {
    try {
        localStorage.removeItem("authUser");

        if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
            const response = yield call(fireBaseBackend.logout);
            yield put(logoutUserSuccess(response));
        }

        history.push('/login');
    } catch (error) {
        yield put(apiError(error));
    }
}

export function* watchUserLogin() {
    yield takeEvery(CHECK_LOGIN, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

function* loginSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
    ]);
}

export default loginSaga;
import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { requestAll, requestExecutiveAll } from "../../store/actions";
import { getCurrentUser } from "../../helpers/Utils";

/* const expandRow = {
    renderer: row => (
        <>
            Action :
            <Link to="#" className="mr-3 text-primary"><i className="mdi mdi-eye font-size-18 ml-2"></i></Link>
             <Link to="#" className="text-danger" ><i className="mdi mdi-trash-can font-size-18"></i></Link>
        </>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true
}; */

const Request = ({
  requestAllAction,
  requestExecutiveAllAction,
  requestAll,
  requestExecutiveAll,
  history,
}) => {
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { title: "TravelQuail", link: "/" },
    { title: "Request", link: "/request" },
  ]);
  const allRequestData = () => {
    requestAllAction(history);
  };
  const allRequestExecutiveData = () => {
    const executive_id = getCurrentUser().id;
    requestExecutiveAllAction(executive_id, history);
  };

  useEffect(() => {
    allRequestData();
    allRequestExecutiveData();
  }, []);

  const requestType = [
    {
      id: 1,
      name: "Offline-booking",
    },
    {
      id: 2,
      name: "Check-in",
    },
    {
      id: 3,
      name: "Meal Request",
    },
    {
      id: 4,
      name: "Seat Request",
    },
    {
      id: 5,
      name: "Extra Baggage",
    },
    {
      id: 6,
      name: "Special Request",
    },
    {
      id: 7,
      name: "Upgrade Request",
    },
    {
      id: 8,
      name: "Lost Baggage",
    },
    {
      id: 9,
      name: "Airline/airport Complaint ",
    },
    {
      id: 10,
      name: "Flight change / cancellation",
    },
    {
      id: 11,
      name: "Flight Refund",
    },
    {
      id: 12,
      name: "Missed flight assistance",
    },
    {
      id: 13,
      name: "Visa Documentation",
    },
    {
      id: 14,
      name: "COVID support",
    },
    {
      id: 15,
      name: "Entry Requirements",
    },
  ];

  const requestStatus = [
    {
      id: 1,
      name: "Requested",
    },
    {
      id: 2,
      name: "Rejected",
    },
    {
      id: 3,
      name: "In Process",
    },
    {
      id: 4,
      name: "Completed",
    },
  ];

  const data = {
    columns: [
      {
        label: "No",
        field: "id",
        sort: "asc",
        width: 78,
      },
      {
        label: "Trip Date",
        field: "trip_date",
        sort: "asc",
        width: 93,
      },
      {
        label: "Trip Name",
        field: "trip_name",
        sort: "asc",
        width: 109,
      },
      {
        label: "Company",
        field: "trip_company",
        sort: "asc",
        width: 48,
      },
      {
        label: "Request Type",
        field: "request_type",
        sort: "asc",
        width: 135,
      },
      {
        label: "Request Status",
        field: "request_status",
        sort: "asc",
        width: 110,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows:
      requestAll &&
      requestAll.reverse().map((req, index) => ({
        id: index + 1,
        trip_date: new Date(req.trip_date).toDateString(),
        trip_name: req.trip_name,
        trip_company: req.trip_company,
        request_type: requestType
          .filter((r) => r.id === req.type)
          .map((r) => r.name),
        request_status: (
          <div className="badge badge-soft-success font-size-12">
            {requestStatus
              .filter((r) => r.id === req.status)
              .map((r) => r.name)}
          </div>
        ),
        action: (
          <>
            <Link
              to={`request-view/${req.id}`}
              className="mr-3 text-primary"
              id="view1"
            >
              <i className="mdi mdi-eye font-size-18"></i>
            </Link>
            <UncontrolledTooltip placement="top" target="view1">
              View Request
            </UncontrolledTooltip>
          </>
        ),
      })),
  };

  const exec = {
    columns: [
      {
        label: "No",
        field: "id",
        sort: "asc",
        width: 78,
      },
      {
        label: "Trip Date",
        field: "trip_date",
        sort: "asc",
        width: 93,
      },
      {
        label: "Trip Name",
        field: "trip_name",
        sort: "asc",
        width: 109,
      },
      {
        label: "Company",
        field: "trip_company",
        sort: "asc",
        width: 48,
      },
      {
        label: "Request Type",
        field: "request_type",
        sort: "asc",
        width: 135,
      },
      {
        label: "Request Status",
        field: "request_status",
        sort: "asc",
        width: 110,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows:
      requestExecutiveAll &&
      requestExecutiveAll.reverse().map((req, index) => ({
        id: index + 1,
        trip_date: new Date(req.trip_date).toDateString(),
        trip_name: req.trip_name,
        trip_company: req.trip_company,
        request_type: requestType
          .filter((r) => r.id === req.type)
          .map((r) => r.name),
        request_status: (
          <div className="badge badge-soft-success font-size-12">
            {requestStatus
              .filter((r) => r.id === req.status)
              .map((r) => r.name)}
          </div>
        ),
        action: (
          <>
            <Link
              to={`/request-view/${req.id}`}
              className="mr-3 text-primary"
              id="view2"
            >
              <i className="mdi mdi-eye font-size-18"></i>
            </Link>
            <UncontrolledTooltip placement="top" target="view2">
              View Request
            </UncontrolledTooltip>
          </>
        ),
      })),
  };

  const options = {
    // pageStartIndex: 0,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    sizePerPageList: [
      {
        text: "5th",
        value: 5,
      },
      {
        text: "10th",
        value: 10,
      },
      {
        text: "All",
        value: data.rows.length,
      },
    ],
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Users" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xl={12}>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">Latest Request</h4>

                      {getCurrentUser().role_id === 1 ||
                      getCurrentUser().role_id === 2 ? (
                        <MDBDataTable responsive data={data} className="mt-4" />
                      ) : (
                        <MDBDataTable responsive data={exec} className="mt-4" />
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ UserRequest }) => {
  const { requestAll, requestExecutiveAll, loading } = UserRequest;

  /* console.log("request alll", requestAll, requestExecutiveAll) */
  return { loading, requestAll, requestExecutiveAll };
};

export default connect(mapStateToProps, {
  requestAllAction: requestAll,
  requestExecutiveAllAction: requestExecutiveAll,
})(Request);

import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Register from "./register";

class UserRegister extends Component {
  state = {
    breadcrumbItems: [
      { title: "TravelQuail", link: "/" },
      { title: "User Registration", link: "/user-register" },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Roles"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Register />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default UserRegister;

import { REGISTER_USER, REGISTER_USER_SUCCESSFUL, REGISTER_USER_FAILED , USER_REGISTER, USER_REGISTER_SUCCESSFUL, USER_REGISTER_FAILED} from './actionTypes';


//admin register
export const registerUser = (user) => {
    return {
        type: REGISTER_USER,
        payload: { user }
    }
}

export const registerUserSuccessful = (user) => {
    return {
        type: REGISTER_USER_SUCCESSFUL,
        payload: user
    }
}

export const registerUserFailed = (error) => {
    return {
        type: REGISTER_USER_FAILED,
        payload: error
    }
}

//user register
export const userRegister = (user) => {
    return {
        type: USER_REGISTER,
        payload: { user }
    }
}

export const userRegisterSuccessful = (user) => {
    return {
        type: USER_REGISTER_SUCCESSFUL,
        payload: user
    }
}

export const userRegisterFailed = (error) => {
    return {
        type: USER_REGISTER_FAILED,
        payload: error
    }
}

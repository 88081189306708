import React, { useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { requestedData, requestStatus } from "../../store/actions";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Formik, Form, Field } from "formik";
import { ImageGroup, Image } from "react-fullscreen-image";

// Required stylesheet
import "react-awesome-lightbox/build/style.css";

const RequestView = ({
  requestedDataAction,
  history,
  requestData,
  requestStatusAction,
  loading,
}) => {
  const id = useParams().id;
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { title: "TravelQuail", link: "/" },
    { title: "Request View", link: "/request-view" },
  ]);

  const getRequestedData = () => {
    requestedDataAction(id, history);
  };

  const initialValues = {
    user_id: requestData.user_id,
    request_id: requestData.id,
    status: requestData.status,
    remark: requestData.remark === null ? "" : requestData.remark,
  };

  const onSubmit = (values, { resetForm }) => {
    const payload = {
      ...values,
    };
    if (!loading) {
      requestStatusAction(values, history);
      resetForm();
    }
    console.log("values", values);
    /*  setTimeout(() => {
            alert(JSON.stringify(payload, null, 2));
            resetForm()

        }, 1000); */
  };
  useEffect(() => {
    getRequestedData();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Request" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xl={12}>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">Request Details</h4>
                      <div className="p-2 mt-2"></div>
                      <Row>
                        <Col>
                          <h6> Trip Info</h6>
                          <p> Name: {requestData.name}</p>
                          <p>
                            Start Date :
                            {new Date(requestData.startDate).toDateString()}
                          </p>
                          <p>
                            End Date :
                            {new Date(requestData.endDate).toDateString()}
                          </p>
                          <p>Company: {requestData.company}</p>
                          <p>Department: {requestData.department}</p>
                          <p>Accomodation: {requestData.accomodation}</p>
                          <p>transportation: {requestData.transportation}</p>
                          <p>Food Beverage: {requestData.food_beverage}</p>
                          <p>Insurance: {requestData.insurance}</p>
                          <p>Other: {requestData.other}</p>
                          {requestData.flights &&
                            requestData.flights.map((flight, index) => {
                              return (
                                <Fragment key={`flight${index}`}>
                                  <h6>Flight Sectors</h6>
                                  <p>Airline: {flight.airline}</p>
                                  <p>
                                    Arrival Airport : {flight.arrival_airport}
                                  </p>
                                  <p>
                                    Arrival Date & Time:{" "}
                                    {new Date(
                                      flight.arrival_date
                                    ).toUTCString()}
                                  </p>
                                  <p>
                                    Departure Airport :{" "}
                                    {flight.departure_airport}
                                  </p>
                                  <p>
                                    Departure Date & Time:{" "}
                                    {new Date(
                                      flight.departure_date
                                    ).toUTCString()}
                                  </p>
                                  <p>PNR : {flight.pnr}</p>
                                </Fragment>
                              );
                            })}
                        </Col>
                        <Col>
                          {requestData.details &&
                            requestData.details.map((d, i) => {
                              return (
                                <Fragment key={`request${i}`}>
                                  <h6>Traveller Profile & Request Details</h6>
                                  <p>Profile Name: {d.profile_name}</p>
                                  <p>
                                    Primary Travel Document :{" "}
                                    {d.travel_documents}
                                  </p>
                                  <p>
                                    Frequent Flyer Details : {d.frequent_flyer}
                                  </p>
                                  <p>
                                    Seating Preference : {d.seating_preference}
                                  </p>
                                  <p>Meal Preference : {d.meal_preference}</p>
                                  <p>Bags to Check in : {d.bags_to_check_in}</p>
                                  <p>Special_Request : {d.special_request}</p>
                                  <p>Extra Baggage : {d.no_of_extra_baggage}</p>
                                  <p>
                                    Weight of Baggage : {d.weight_of_baggage}
                                  </p>
                                  <p>Notes : {d.notes}</p>
                                  <h6>Identity Documents</h6>
                                  {d.identity_documents.map(
                                    (identity, index) => {
                                      return (
                                        <Fragment key={`identity${index}`}>
                                          <p>
                                            Name: {identity.title}{" "}
                                            {identity.first_name}{" "}
                                            {identity.last_name}
                                          </p>
                                          <p>
                                            Document Type: {identity.doc_type}
                                          </p>
                                          <p>
                                            Document Name: {identity.doc_name}
                                          </p>
                                          <p>
                                            Document Number:{" "}
                                            {identity.doc_number}
                                          </p>
                                          <p>
                                            Date of Birth:{" "}
                                            {new Date(
                                              identity.dob
                                            ).toDateString()}
                                          </p>
                                          <p>
                                            Document Issuing Date:{" "}
                                            {new Date(
                                              identity.issuing_date
                                            ).toDateString()}
                                          </p>
                                          <p>
                                            Country of Issue:{" "}
                                            {identity.issuing_country}
                                          </p>
                                          <p>
                                            State of Issue:{" "}
                                            {identity.issuing_state}
                                          </p>
                                          <p>
                                            Address of Issue:{" "}
                                            {identity.issuing_address}
                                          </p>
                                          <p>Pin code: {identity.pin_code}</p>
                                        </Fragment>
                                      );
                                    }
                                  )}
                                </Fragment>
                              );
                            })}
                        </Col>
                        <Col>
                          {requestData.travel_documents &&
                            requestData.travel_documents.map((doc, index) => {
                              return (
                                <Fragment key={`doc${index}`}>
                                  <h6>Traveller Documents</h6>
                                  <p>Document Name: {doc.document_name}</p>
                                  <p>
                                    Document Category: {doc.document_category}
                                  </p>
                                  <p>
                                    Document Sub-Category:{" "}
                                    {doc.document_subcategory}
                                  </p>
                                  <p>
                                    Document Type: {doc.trip_profile_document}
                                  </p>
                                  <p>
                                    Valid From:{" "}
                                    {new Date(doc.valid_from).toDateString()}
                                  </p>
                                  <p>
                                    Valid To:{" "}
                                    {new Date(doc.valid_to).toDateString()}
                                  </p>

                                  <ImageGroup>
                                    <ul className="images">
                                      {JSON.parse(doc.dropzone).map(
                                        (i, index) => (
                                          <li key={`image${index}`}>
                                            <Image
                                              src={i.url}
                                              alt="nature"
                                              style={{
                                                position: "relative",
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                height: "100%",
                                                width: "100%",
                                                objectFit: "contain",
                                              }}
                                            />
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </ImageGroup>
                                </Fragment>
                              );
                            })}
                        </Col>
                      </Row>

                      <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        enableReinitialize={true}
                      >
                        {({
                          handleSubmit,
                          setFieldValue,
                          setFieldTouched,
                          handleChange,
                          handleBlur,
                          values,
                          errors,
                          touched,
                          isSubmitting,
                        }) => (
                          <Form>
                            <FormGroup className="form-group has-float-label">
                              <Label>Remark</Label>
                              <Field
                                className="form-control"
                                name="remark"
                                component="textarea"
                                value={values.remark}
                                placeholder="Remark if Any"
                              />
                            </FormGroup>

                            <button
                              name="status"
                              type="submit"
                              className="btn btn-success"
                              onClick={() => (values.status = 3)}
                            >
                              Accept
                            </button>
                            <button
                              name="status"
                              type="submit"
                              className="btn btn-danger ml-2 mr-2"
                              onClick={() => (values.status = 2)}
                            >
                              Reject
                            </button>
                            <button
                              name="status"
                              type="submit"
                              className="btn btn-success "
                              onClick={() => (values.status = 4)}
                            >
                              Complete
                            </button>
                          </Form>
                        )}
                      </Formik>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ UserRequest }) => {
  const { requestData, loading } = UserRequest;

  console.log("request dataa", requestData);
  return { loading, requestData };
};

export default connect(mapStateToProps, {
  requestedDataAction: requestedData,
  requestStatusAction: requestStatus,
})(RequestView);

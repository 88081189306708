import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
/* import Register from "../pages/Authentication/Register"; */
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";
import Roles from "../pages/Roles/roles";
import Request from "../pages/request/request";
import RequestView from "../pages/request/requestView";
import UserRegister from "../pages/userRegister/userRegister"
import Email from "../pages/request/email"
import Clients from "../pages/Users/clients"

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import Users from "../pages/Users/users"


const authProtectedRoutes = [

	{ path: "/dashboard", component: Dashboard },
	{ path: "/users", component: Users },
	{ path: "/roles", component: Roles },
	{ path: "/request", component: Request},
	{ path: "/user-register", component: UserRegister},
	{ path: "/email", component: Email},
	{ path: "/request-view/:id", component: RequestView },
	{ path: "/clients", component: Clients},
	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
	/* { path: "/register", component: Register },	 */
	{ path: "/auth-lock-screen", component: AuthLockScreen },
];

export { authProtectedRoutes, publicRoutes };
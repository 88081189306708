import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import request from "request"
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

//Account Redux states
import { REGISTER_USER , USER_REGISTER} from './actionTypes';
import { registerUserSuccessful, registerUserFailed, userRegisterSuccessful, userRegisterFailed } from './actions';
import {API_URL} from "../../../apiUrl"

//AUTH related methods
import { postRegister } from '../../../helpers/fackBackend_Helper';
import { getFirebaseBackend } from '../../../helpers/firebase_helper';
// initialize firebase Auth
const fireBaseBackend = getFirebaseBackend();



// Is user register successfull then direct plot user in redux.


export function* watchUserRegister() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(REGISTER_USER, registerUserDetails)
}

const postRegisterSync = async (name, email, password, role_id) => {

    var options = {
        'method': 'POST',
        'url': `${API_URL}/admin/create_user`,
        'headers': {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "name": name, "email": email, "password": password, "role_id": role_id })

    };    
    await request(options, function (error, profile) {
        if (error) {
           
            toastr.warning(error, 'Account Error')
        } else {
            var parsedJSON = JSON.parse(profile.body)            
            put(registerUserSuccessful(parsedJSON));
            if (profile.statusCode === 200) {                
                toastr.success(parsedJSON.msg, 'Account Created')
               
            } else if (profile.statusCode === 206) {    
               toastr.warning(parsedJSON.msg, 'Error in creating Account')
   
            }
        }
    });

    

}




function* registerUserDetails({ payload }) {
    const { name, email, password, role_id } = payload.user;
    try {
        /* if(process.env.REACT_APP_DEFAULTAUTH === "firebase"){
            const response = yield call(fireBaseBackend.registerUser, user.email, user.password);
            yield put(registerUserSuccessful(response));
        }
        else{ */
        const response = yield call(postRegisterSync, name, email, password, role_id);
        
        yield put(registerUserSuccessful(response));       
        /*  } */
    } catch (error) {
        yield put(registerUserFailed(error));
    }
}
/* --------------------------------------------------------------------- */


/* USER REGISTER */
export function* watchRegister() {
     yield takeEvery(USER_REGISTER, registerDetails)
}



function* registerDetails({ payload }) {
    const { name, mobile, email, password,roles } = payload.user;
    try {
       
        const response = yield call(RegisterSync, name, mobile, email, password,roles );
        
        yield put(userRegisterSuccessful(response));       
       
    } catch (error) {
        yield put(userRegisterFailed(error));
    }
}

const RegisterSync = async (name, mobile, email, password,roles ) => {


    var options = {
        'method': 'POST',
        'url': `${API_URL}/user/register`,
        'headers': {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "name": name, "mobile":mobile, "email": email, "password": password,"roles": roles})

    };    
    await request(options, function (error, profile) {
        if (error) {
           
            toastr.warning(error, 'Account Error')
        } else {
            var parsedJSON = JSON.parse(profile.body)            
            put(userRegisterSuccessful(parsedJSON));
            if (profile.statusCode === 200) {                
                toastr.success(parsedJSON.msg, 'User Account Created')
               
            } else if (profile.statusCode === 206) {    
               toastr.warning(parsedJSON.msg, 'Error in creating User Account')
   
            }
        }
    });

    

}








function* accountSaga() {
    yield all([
        fork(watchUserRegister),
        fork(watchRegister)

    ]);
}

export default accountSaga;
import {
    REQUEST_ALL,
    REQUEST_ALL_SUCCESSFUL,
    REQUEST_ALL_FAILED,
    REQUEST_EXECUTIVE_ALL,
    REQUEST_EXECUTIVE_ALL_SUCCESSFUL,
    REQUEST_EXECUTIVE_ALL_FAILED,
    REQUESTED_DATA,
    REQUESTED_DATA_SUCCESSFUL,
    REQUESTED_DATA_FAILED,
    REQUESTED_STATUS,
    REQUESTED_STATUS_SUCCESSFUL,
    REQUESTED_STATUS_FAILED

} from './actionTypes';

const initialState = {
    requestAll: [],
    requestExecutiveAll: [],
    requestData: [],
    error: null,
    message: null,
    loading: false
}

const UserRequest = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_ALL:
            state = {
                ...state,
                loading: true,
                error: null
            }
            break;
        case REQUEST_ALL_SUCCESSFUL:
            state = {
                ...state,
                requestAll: action.payload,
                loading: false,
                message: action.message
            }
            break;
        case REQUEST_ALL_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.error
            }
            break;
        case REQUEST_EXECUTIVE_ALL:
            state = {
                ...state,
                loading: true,
                error: null
            }
            break;
        case REQUEST_EXECUTIVE_ALL_SUCCESSFUL:
            state = {
                ...state,
                requestExecutiveAll: action.payload,
                loading: false,
                message: action.message
            }
            break;
        case REQUEST_EXECUTIVE_ALL_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.error
            }
            break;
        case REQUESTED_DATA:
            state = {
                ...state,
                loading: true,
                error: null
            }
            break;
        case REQUESTED_DATA_SUCCESSFUL:
            state = {
                ...state,
                requestData: action.payload,
                loading: false,
                message: action.message
            }
            break;
        case REQUESTED_DATA_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.error
            }
            break;
        case REQUESTED_STATUS:
            state = {
                ...state,
                loading: true,
                error: null
            }
            break;
        case REQUESTED_STATUS_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                message: action.message
            }
            break;
        case REQUESTED_STATUS_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.error
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default UserRequest;
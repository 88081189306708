import React, { useEffect } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux"
import { MDBDataTable } from "mdbreact";


import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { requestAll, requestExecutiveAll } from "../../store/actions";
import { getCurrentUser } from "../../helpers/Utils"

const expandRow = {
  renderer: row => (
    <>
      Action :
      <Link to="#" className="mr-3 text-primary"><i className="mdi mdi-eye font-size-18 ml-2"></i></Link>
      {/*  <Link to="#" className="text-danger" ><i className="mdi mdi-trash-can font-size-18"></i></Link> */}
    </>
  ),
  showExpandColumn: true,
  expandByColumnOnly: true
};

const LatestTransactions = ({ requestAllAction, requestExecutiveAllAction, requestAll, requestExecutiveAll, history }) => {

  const allRequestData = () => {
    requestAllAction(history)

  }
  const allRequestExecutiveData = () => {
    const executive_id = getCurrentUser().id
    requestExecutiveAllAction(executive_id, history)
  }

  useEffect(() => {
    allRequestData()
    allRequestExecutiveData()
  }, [])

  const requestType = [
    {
      id: 1, name: "Offline-booking"
    },
    {
      id: 2, name: "Check-in"
    },
    {
      id: 3, name: "Meal Request"
    },
    {
      id: 4, name: "Seat Request"
    },
    {
      id: 5, name: "Extra Baggage"
    },
    {
      id: 6, name: "Special Request"
    },
    {
      id: 7, name: "Upgrade Request"
    },
    {
      id: 8, name: "Lost Baggage"
    },
    {
      id: 9, name: "Airline/airport Complaint "
    },
    {
      id: 10, name: "Flight change / cancellation"
    },
    {
      id: 11, name: "Flight Refund"
    },
    {
      id: 12, name: "Missed flight assistance"
    },
    {
      id: 13, name: "Visa Documentation"
    },
    {
      id: 14, name: "COVID support"
    },
    {
      id: 15, name: "Entry Requirements"
    }
  ]

  const requestStatus = [
    {
      id: 1, name: 'Requested'
  },
  {
      id: 2, name: "Rejected"
  },
  {
      id: 3, name: "In Process"
  },
  {
      id: 4, name: "Completed"
  }
  ]


  const data = {
    columns: [
      {
        dataField: 'id',
        text: 'No.'
      },
      {
        dataField: "trip_date",
        text: "Trip Date"
      },
      {
        dataField: "trip_name",
        text: "Trip Name"
      },
      {
        dataField: "trip_company",
        text: "Company"
      },
      {
        dataField: "request_type",
        text: "Request Type"
      },
      {
        dataField: "request_status",
        text: "Request Status"
      },
    ],
    rows: requestAll && requestAll.reverse().map((req, index) => (
      {

        id: index + 1,
        trip_date: new Date(req.trip_date).toDateString(),
        trip_name: req.trip_name,
        trip_company: req.trip_company,
        request_type: requestType.filter(r => r.id === req.type).map(r => r.name),
        request_status: <div className="badge badge-soft-warning font-size-12">{requestStatus.filter(r => r.id === req.status).map(r => r.name)}</div>

      }

    )),


  };


  const exec = {
    columns: [
      {
        dataField: 'id',
        text: 'No.'
      },
      {
        dataField: "trip_date",
        text: "Trip Date"
      },
      {
        dataField: "trip_name",
        text: "Trip Name"
      },
      {
        dataField: "trip_company",
        text: "Company"
      },
      {
        dataField: "request_type",
        text: "Request Type"
      },
      {
        dataField: "request_status",
        text: "Request Status"
      },
    ],
    rows: requestExecutiveAll && requestExecutiveAll.reverse().map((req, index) => (
      {

        id: index + 1,
        trip_date: new Date(req.trip_date).toDateString(),
        trip_name: req.trip_name,
        trip_company: req.trip_company,
        request_type: requestType.filter(r => r.id === req.type).map(r => r.name),
        request_status: <div className="badge badge-soft-warning font-size-12">{requestStatus.filter(r => r.id === req.status).map(r => r.name)}</div>

      }

    )),


  };




  const options = {
    // pageStartIndex: 0,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    sizePerPageList:
      [{
        text: '5th', value: 5
      }, {
        text: '10th', value: 10
      }, {
        text: 'All', value: data.rows.length
      }]

  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true
  };

  return (
    <React.Fragment>
      <Col lg={12}>
        <Card>
          <CardBody>
            {/* <Dropdown isOpen={this.state.menu} toggle={() => this.setState({menu : !this.state.menu})} className="float-right">
                                            <DropdownToggle tag="i" className="arrow-none card-drop">
                                                <i className="mdi mdi-dots-vertical"></i>
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                
                                                <DropdownItem>Sales Report</DropdownItem>
                                                
                                                <DropdownItem>Export Report</DropdownItem>
                                                
                                                <DropdownItem>Profit</DropdownItem>
                                                
                                                <DropdownItem>Action</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown> */}

            <h4 className="card-title mb-4">Latest Request</h4>
            {getCurrentUser().role_id === 1 || getCurrentUser().role_id === 2 ?
              <BootstrapTable
                keyField='id'
                data={data.rows}
                columns={data.columns}
                /*  expandRow={ expandRow } */
                pagination={paginationFactory(options)}
              /*  selectRow={ selectRow } */
              /> : 
              <BootstrapTable
                keyField='id'
                data={exec.rows}
                columns={exec.columns}
                /*  expandRow={ expandRow } */
                pagination={paginationFactory(options)}
              /*  selectRow={ selectRow } */
              />}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );

}

const mapStateToProps = ({ UserRequest }) => {
  const { requestAll, requestExecutiveAll, loading } = UserRequest;

  console.log("request alll", requestAll, requestExecutiveAll)
  return { loading, requestAll, requestExecutiveAll };

};

export default connect(mapStateToProps, { requestAllAction: requestAll, requestExecutiveAllAction: requestExecutiveAll })(LatestTransactions);